import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Funnel from './funnel';

function App() {
  // ReactGA.initialize("G-B7LBY51F0E");
  //  ReactGA.send({ 
  //   hitType: "pageview", 
  //   page: window.location.pathname, 
  //   title: "Custom Title" 
  // });

  return (
    <Router>
      <Routes>
      `<Route path="/" element= {<Funnel />} />
      </Routes>
    </Router>
  );
}

export default App;
