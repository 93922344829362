import React, { Component, useState, useEffect } from "react";
import API_BASE_URL from './config/apiConfig';
import 'bootstrap/dist/css/bootstrap.min.css';

function Funnel({ }) {

  

  const [totalUsers, setTotalUsers] = useState(0);
  const [usersWithAuthCode, setUsersWithAuthCode] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [AllProjects, setAllProjects] = useState(0);
  const [usersWithMultipleProjects, setUsersWithMultipleProjects] = useState(0);
  const [projectsWithNonOwnerTeamMembers, setProjectsWithNonOwnerTeamMembers] = useState(0);

  const fetchCounts = async () => {
    try {
      const userCountRes = await fetch(API_BASE_URL+'/api/funnel/usersCount');
      const usersWithAuthCodeRes = await fetch(API_BASE_URL+'/api/funnel/cardCount');
      const projectCountRes = await fetch(API_BASE_URL+'/api/funnel/uniqueProjectsCount');
      const allProjectCountRes = await fetch(API_BASE_URL+'/api/funnel/projectsCount');
      const usersWithMultipleProjectsRes = await fetch(API_BASE_URL+'/api/funnel/multipleProjectsCount');
      const projectsWithNonOwnerTeamMembersRes = await fetch(API_BASE_URL+'/api/funnel/teamProjectsCount');

      const userCountData = await userCountRes.json();
      const usersWithAuthCodeData = await usersWithAuthCodeRes.json();
      const projectCountData = await projectCountRes.json();
      const allProjectCountData = await allProjectCountRes.json();
      const usersWithMultipleProjectsData = await usersWithMultipleProjectsRes.json();
      const projectsWithNonOwnerTeamMembersData = await projectsWithNonOwnerTeamMembersRes.json();

      setTotalUsers(userCountData.totalUsers);
      setUsersWithAuthCode(usersWithAuthCodeData.usersWithAuthCode);
      setTotalProjects(projectCountData.uniqueUsers);
      setAllProjects(allProjectCountData.totalProjects);
      setUsersWithMultipleProjects(usersWithMultipleProjectsData.usersWithMultipleProjects);
      setProjectsWithNonOwnerTeamMembers(projectsWithNonOwnerTeamMembersData.projectsWithNonOwnerTeamMembers);
    } catch (error) {
      console.error('Error fetching counts:', error);
    }
  };

  useEffect(() => {
    // Fetch data initially
    fetchCounts();

    // Set up polling to fetch data every 10 seconds (10000 milliseconds)
    const intervalId = setInterval(fetchCounts, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container">
      <h1 className="funHead">The funnel</h1>
      <div className="row">
        <div className="col-md-4">
          <div className="funnelHold">
          <p className="funnelP1">{totalUsers}</p>
          <p className="funnelP2">Total Users</p>
          </div>
        </div>

        <div className="col-md-4">
          <div className="funnelHold">
            <p className="funnelP1">{usersWithAuthCode}</p>
            <p className="funnelP2">Users with Cards</p>
          </div>
        </div>

        <div className="col-md-4">

          <div className="funnelHold">
            <p className="funnelP1">{totalProjects}</p>
            <p className="funnelP2">Users with Projects</p>
          </div>
          
        </div>

         <div className="col-md-4">

          <div className="funnelHold">
            <p className="funnelP1">{AllProjects}</p>
            <p className="funnelP2">Total Project</p>
          </div>
          
        </div>


        <div className="col-md-4">

          <div className="funnelHold">
            <p className="funnelP1">{usersWithMultipleProjects}</p>
            <p className="funnelP2">Users with Multiple Projects</p>
          </div>
          
        </div>

         <div className="col-md-4">

          <div className="funnelHold">
            <p className="funnelP1">{projectsWithNonOwnerTeamMembers}</p>
            <p className="funnelP2">UCollaborators</p>
          </div>
          
        </div>

        

      
      </div>

    </div>
  );
    }




  export default Funnel;
